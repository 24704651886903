import fetchUmbracoData from '@limbo-works/umbraco-client/client';

import doctypes, {
	getDoctypeFromSolutionAndTemplate,
} from '~/assets/js/doctypes';
import { getLayoutFromSolutionAndTemplate } from '~/assets/js/layouts';

const DEFAULT_SOLUTION_NAME = 'main';
const DEFAULT_THEME = 'red';

export const state = () => ({
	template: null,
	error: null,
	site: null,
	currentPage: {},
	theme: DEFAULT_THEME,
	isLoggedIn: false,
});

export const actions = {
	async nuxtServerInit(
		_,
		{ req, $config, error, redirect, route, store: { commit } }
	) {
		let displayErrorPage = false;
		const data = await fetchUmbracoData({
			endpointUrl: process.env.REDIS_URL
				? process.env.GET_DATA_ENDPOINT
				: undefined,
			onResponse(response) {
				process.env.NODE_ENV === 'development' &&
					console.log('Store requesting', response?.config?.url);
				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				return response;
			},
			error: (err) => {
				displayErrorPage = true;
				return error(err);
			},
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['site', 'content'],
				appHost: $config.APP_HOST || req.headers.host,
			},
			redirect: (...args) => {
				redirect(...args);
			},
			route,
			headers: {
				cookie: req?.headers?.cookie || '',
			},
		});

		process.env.NODE_ENV === 'development' &&
			console.log('Is from cache?', !!data?.isFromCache);

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { site = {}, content = {} } = data;
		site.appHost = site.appHost || $config.APP_HOST || req.headers.host;

		commit('SET_SITE_DATA', site);
		commit('SET_CURRENT_PAGE', content);
		commit('SET_TEMPLATE', content.template || site.pageTemplateAlias);
		commit('SET_THEME', content.colorScheme);

		if (displayErrorPage) {
			commit('SET_ERROR_DATA', content);
		}
	},
};

export const mutations = {
	SET_TEMPLATE(state, template) {
		state.template = template;
	},

	SET_THEME(state, color) {
		if (color && !['red', 'blue'].includes(color)) {
			color = 'blue';
		}
		state.theme = color || DEFAULT_THEME;
	},

	SET_ERROR_DATA(state, error) {
		state.error = error;
	},

	SET_SITE_DATA(state, site) {
		if (!site) {
			return;
		}

		state.site = site;
	},
	SET_CURRENT_PAGE(state, page) {
		state.currentPage = {
			title: page?.title || page?.name,
			id: page?.id,
		};
	},

	SET_IS_LOGGED_IN(state, isLoggedIn) {
		state.isLoggedIn = isLoggedIn;
	},

	SET_LANGUAGE_PICKER_VISIBILITY(state, isVisible) {
		state.isLanguagePickerVisible = isVisible;
	},
};

export const getters = {
	solution: ({ site }) => site?.alias ?? DEFAULT_SOLUTION_NAME,

	layout: ({ template }, { solution }) => {
		// Get from solution
		let layout = getLayoutFromSolutionAndTemplate(solution, template);

		// Get from shared
		if (layout.indexOf('/Default') >= 0) {
			const sharedLayout = getLayoutFromSolutionAndTemplate(
				'shared',
				template
			);
			if (sharedLayout.indexOf('/Default') < 0) {
				layout = sharedLayout;
			}
		}

		return layout;
	},

	doctype: ({ template }, { solution }) => {
		// Get from solution
		const doctype = getDoctypeFromSolutionAndTemplate(solution, template);
		if (Object.keys(doctypes).includes(doctype)) {
			return doctype;
		}

		// Get from shared
		const newDoctype = getDoctypeFromSolutionAndTemplate(
			'shared',
			template
		);
		if (Object.keys(doctypes).includes(newDoctype)) {
			return newDoctype;
		}

		return doctype;
	},

	theme: ({ theme }) => theme,

	isLoggedIn: ({ isLoggedIn }) => isLoggedIn,

	isLanguagePickerVisible: ({ isLanguagePickerVisible }) =>
		isLanguagePickerVisible,
};
